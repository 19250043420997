import { Doughnut, mixins } from "vue-chartjs";
export default {
  extends: Doughnut,
  props: ["data", "options"],
  data () {
    return {
      defaultStyle: {
        borderWidth: "10px",
        hoverBackgroundColor: "red",
        hoverBorderWidth: "10px",
        legend: {
          display: false
        }
      }
    }

  },
  mounted() {
    if(this.options && this.options.renderOnMount !== false){
      this.renderCustom();
    }
  },
  methods: {
    renderCustom(data, styleObj){
      if(!data){
        data = this.data;
      }
      if(!styleObj){
        styleObj = this.defaultStyle;
      }
      this.renderChart(data, styleObj);
    }
  }
};
